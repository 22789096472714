//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Loading from "vue-loading-overlay";
import DialogMessage from "../elements/DialogMessage";
import FMS from "../../services/FMS";
import {createWorldTerrain, Viewer, SampledPositionProperty, JulianDate, ClockRange, TimeIntervalCollection, TimeInterval,
  IonResource, VelocityOrientationProperty, LinearApproximation, Cartesian3, Transforms, HeadingPitchRoll, Math, Ion} from 'cesium';
import "cesium/Build/Cesium/Widgets/widgets.css";

export default {
  sockets:{
    connect: function(){
      console.log('socket connected')
    },
    datastream: function(val){

      var data = val.data[0]

      this.actuallat = data.lat
      this.actuallng = data.lon
      document.getElementById("livedelivery").innerHTML  = data.state
      document.getElementById("groundspeed").innerHTML  = (data.groundspeed * 1000/3600).toFixed(2) + " m/s"
      document.getElementById("airspeed").innerHTML  = (data.airspeed * 1000/3600).toFixed(2) + " m/s"
      document.getElementById("relativealtitude").innerHTML  = data.rel_alt + " m"
      document.getElementById("absolutealtitude").innerHTML  = data.alt + " m"

      var newseconds = new Date();
      var timeseconds = (newseconds.getTime() - this.datedate.getTime())/1000;
      this.datedate = newseconds;

      this.time = JulianDate.addSeconds(this.time, timeseconds+0.5, new JulianDate());
      this.dummyposition = Cartesian3.fromDegrees(data.lon, data.lat, data.alt);
      this.positions.addSample(this.time, this.dummyposition);

      this.viewer.trackedEntity = this.airplaneEntity;

      if(this.airplaneEntity !== undefined && this.airplaneEntity !== null) {
        if (data.pitch !== undefined && data.roll !== undefined) {
          this.airplaneEntity.orientation = Transforms.headingPitchRollQuaternion(this.dummyposition,
              new HeadingPitchRoll(Math.toRadians(data.hdg), Math.toRadians(data.pitch), Math.toRadians(data.roll)));
        } else {
          this.airplaneEntity.orientation = Transforms.headingPitchRollQuaternion(this.dummyposition,
              new HeadingPitchRoll(Math.toRadians(data.hdg), Math.toRadians(0.0), Math.toRadians(0.0)));
        }
      }
    }
  },
  name: "OperationsPerformance",
  props: {
    mapConfig: Object,
  },
  components: {
    Loading,
    DialogMessage
  },
  data() {
    return {
      viewer: undefined,
      actuallat: 19,
      actuallng: 19,
      billboard: {},
      offset: [10, 25],
      otherOpts: {
        offset: [0, 32],
        position: 'bottom-right'
      },
      point: {
        pixelSize: 28,
        color: 'red'
      },
      label: {
        text: 'Hello World',
        pixelOffset: [0, 150]
      },

      map: null,
      loading: false,
      showDialog: false,
      title: '',

      ordernotfoundtoogle: false,
      middlemiletoogle: false,
      waitingtoogle: false,
      orderflyingtoogle: false,
      order: null,

      dataState: '',
      dataGnd: '',
      dataAir: '',
      dataRelative: '',
      dataAbsolute: '',

      positions: new SampledPositionProperty(),
      datedate: new Date(),
      start: JulianDate.fromDate(new Date()),
      stop: JulianDate.addSeconds(JulianDate.fromDate(new Date()), 3600, new JulianDate()),
      time: JulianDate.fromDate(new Date()).clone(),
      dummyposition: Cartesian3.fromDegrees(-112.110693, 36.0994841, 1000),
      airplaneEntity: undefined,

      isConnected: false,
      socketMessage: ''
    };
  },
  computed: {

  },
  async mounted() {
    Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJkYjkxYzg3MS1hMmE1LTQwNDgtYmE0Ni1hOGQ5YmRiMjUxNDAiLCJpZCI6NTc3NDAsImlhdCI6MTYzNDE1ODkwNn0.EH7JES1p11NtpGhA_dONgXW1im80ZtveYMbcjMxrMUM';
    this.positions = new SampledPositionProperty();

    this.viewer = new Viewer('cesiumContainer', {
      terrainProvider: createWorldTerrain({
        requestWaterMask: true,
        requestVertexNormals: true
      }),
      timeline: false,
      shouldAnimate: true,
      baseLayerPicker: false,
      geocoder: false,
      homeButton: false,
      sceneModePicker: false
    });

    this.viewer.clock.startTime = this.start.clone();
    this.viewer.clock.stopTime = this.stop.clone();
    this.viewer.clock.currentTime = this.start.clone();
    this.viewer.clock.clockRange = ClockRange.CLAMPED;
    this.viewer.clock.multiplier = 1;

    const airplaneUri = await IonResource.fromAssetId(529826);

    this.airplaneEntity = this.viewer.entities.add({
      availability: new TimeIntervalCollection([
        new TimeInterval({
          start: this.start,
          stop: this.stop,
        }),
      ]),
      model: {
        uri: airplaneUri,
        minimumPixelSize: 128,
        maximumScale: 1,
        scale: 0.01
      },
      position: this.positions,
      orientation: new VelocityOrientationProperty(this.positions)
    });

    this.viewer.trackedEntity = this.airplaneEntity;

    this.airplaneEntity.position.setInterpolationOptions({
      interpolationDegree: 1,
      interpolationAlgorithm: LinearApproximation,
    });

    console.log(this.viewer)
  },
  watch: {

  },
  async created() {
    var order = await FMS.getOrderStatus(this.$route.params.pathMatch, "", this.$session.get("env"))
    console.log(order)
    if (order.ok === false) {
      this.ordernotfoundtoogle = true
    }
    else{
      this.ordernotfoundtoogle = false
      this.order = order.order
      var state = this.order.state

      document.getElementById("status").innerHTML  = state
      if(state === 'delivered'){
        this.orderflyingtoogle = false
        document.getElementById("details").innerHTML  = "Your package was delivered"
      }
      else if(state === 'pending'){
        this.orderflyingtoogle = false
        document.getElementById("details").innerHTML  = "Your package is in way to the hub"
      }
      else if(state === 'accepted'){
        this.orderflyingtoogle = false
        document.getElementById("details").innerHTML  = "Your package is at the hub, waiting to fly"
      }
      else if(state === 'flying'){
        this.orderflyingtoogle = true
        document.getElementById("details").innerHTML = "Your package is flying"
        document.getElementById("livedelivery").innerHTML = this.dataState
        document.getElementById("groundspeed").innerHTML = this.dataGnd
        document.getElementById("airspeed").innerHTML = this.dataAir
        document.getElementById("relativealtitude").innerHTML = this.dataRelative
        document.getElementById("absolutealtitude").innerHTML = this.dataAbsolute
      }
    }
    this.waitingtoogle = true
  },
  methods: {
    confirm(){

    },
  }
};
